.App {
  text-align: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn{
  padding: 15px;
  border-radius: 4px;
}

.form-item-group{
  margin-top: 10px;
  width: 400px;
  padding: 10px 20px;
}

input[type=text]{
  display: inline;
  text-align: start;
  margin-top: 5px;
  width: 100%;
}
input[type=email]{
  display: inline;
  text-align: start;
  margin-top: 5px;
  width: 100%;
}

select{
  padding: 10px 20px;
    border-radius: 6px;
    margin-top: 25px;
    width: 100%;
}

textarea{
  display: inline;
  text-align: start;
  margin-top: 5px;
  width: 100%;
  border-radius: 6px;
  padding: 10px 20px; 
}

.div{
  align-items: center;
  display:flex;
  justify-content: center;
}
.mesaj{
  display: inline;
  width: 150px;
  font-size: 25px;
}

input[type=email]{
  padding: 10px 20px;
    border-radius: 6px;
    margin-top: 25px;
}

table{
  margin-top: 30px;
  /* border: 2px solid red */
}
tr{
  font-size: 20px;
  width: 300px;
}

td{
  padding: 10px 20px;
  border: 2px solid red;
}